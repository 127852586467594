import type { SummarySchedule } from "@prisma/client";

// PUBLIC SOURCES

export const publicPathSourceIds: Record<string, string> = {
  "/hacker-news": "d855b3b5-ab48-46ad-99e2-c1e491fe06fd",
  "/hacker-news/weekly": "e06003e0-abd5-466a-9281-5b5feb927662",
  "/reddit/worldnews": "e2b1df77-16ed-4816-bdf9-700e7f295ed4",
  "/reddit/askreddit": "67847c7b-6024-4d62-8e9a-4e44f5a15ecd",
  "/reddit/gaming": "81a4b3ee-5dcd-488b-b1b9-d7d209f9a284",
  "/reddit/todayilearned": "2ed505ad-318c-48f6-8707-8d113dd61c51",
  "/reddit/science": "150508c6-cc63-4ebe-bbc4-74013d9b7a53",
  "/reddit/news": "7dbd3186-4601-4666-8f44-c1b40df4b9d0",
  "/reddit/sports": "de1dee65-f7a1-4eef-9d99-d258b8d561a1",
};

export const sourceIdPublicPaths: Record<string, string> = Object.fromEntries(
  Object.entries(publicPathSourceIds).map(([path, sourceId]) => [sourceId, path]),
);

// ENCODE/DECODE

const encodeChars = "123456789abcdefghijkmnopqrtuvwxyz";
const base = BigInt(encodeChars.length);

export function encodeShortId(sourceId: string, shortId: number) {
  let num = BigInt(shortId);
  let encoded = "";

  while (num > 0n) {
    const remainder = Number(num % base);
    encoded = encodeChars[remainder] + encoded;
    num = num / base;
  }

  encoded = encoded || "1";

  return `${sourceId.substring(0, 8 - encoded.length)}s${encoded}`;
}

/**
 * Returns tuple of shortId and sourceId prefix (that can be used to verify that the ID is valid)
 * @param encodedId
 * @returns
 */
export function decodeShortId(encodedId: string): { shortId?: number; sourceIdPrefix?: string } {
  try {
    const sIndex = encodedId.indexOf("s");
    if (sIndex === -1) {
      console.error(`Invalid source shortId: ${encodedId}`);
      return {};
    }
    const sourceIdPrefix = encodedId.substring(0, sIndex);
    const encodedShortId = encodedId.substring(sIndex + 1);
    let num = 0n;

    for (const char of encodedShortId) {
      const value = encodeChars.indexOf(char);
      if (value === -1) {
        console.error(`Invalid source shortId: ${encodedId}`);
        return {};
      }
      num = num * base + BigInt(value);
    }

    return { shortId: Number(num), sourceIdPrefix };
  } catch (error) {
    console.error(`Invalid source shortId: ${encodedId}`, error);
    return {};
  }
}

export function privateSourceSlug(source: {
  sourceName: string;
  sourceId: string;
  shortId: number;
}) {
  const normalized = source.sourceName.toLowerCase().replace(/[^a-z0-9]/g, "-");
  return `/${encodeShortId(source.sourceId, source.shortId)}/${normalized}`;
}

export function sourceSlug(source: {
  sourceName: string;
  sourceId: string;
  shortId: number;
}) {
  const publicPath = sourceIdPublicPaths[source.sourceId];
  if (publicPath) {
    return publicPath;
  }
  return privateSourceSlug(source);
}

export const scheduleOptions = [
  { label: "Daily", value: "daily" },
  { label: "On workdays", value: "workdays" },
  { label: "Weekly", value: "weekly" },
];

export function getScheduleLabel(schedule: SummarySchedule) {
  return scheduleOptions.find((option) => option.value === schedule)?.label;
}
