import type { Source, SourceType } from "@prisma/client";
import { multimethod } from "@whimsical-code/multimethod";
import React from "react";
import { StandaloneDialog, type StandaloneDialogRef } from "~/components/ui/dialog";
import type { SettingsSourceShape } from "~/pages/settings/layout";

export const allSourceTypes: SourceType[] = ["whimsical", "slack", "reddit", "hn", "bsky"];

// META

export interface SourceMeta {
  name: string;
  description: string;
  icon: string;
  iconMono: string;
  authPath?: string;
  authDialog?: boolean; // if true, needs to implement AuthDialogContent
  singleton?: boolean;
  alwaysShowSourceName?: boolean; // if true, show the source name even if there is only one source
}

export const getSourceMeta = multimethod<[SourceType], SourceType, SourceMeta>((sourceType) => sourceType);

// DEFAULT CONFIG

export const getDefaultSourceConfig = multimethod(
  (sourceType: SourceType) => sourceType,
  (_): PrismaJson.SourceConfig => ({}),
);

// SETTINGS UI

export const SourceTypeSettings = multimethod(
  ({
    source,
  }: {
    source: SettingsSourceShape;
    config: PrismaJson.SourceConfig;
    setConfig: (config: PrismaJson.SourceConfig) => void;
  }) => source.sourceType,
  (_): React.ReactNode => null,
);

// AUTH

export const AuthDialogContent = multimethod<
  [{ sourceType: SourceType; closeDialog: () => void }],
  SourceType,
  React.ReactNode
>(({ sourceType }) => sourceType);

export const AuthDialog = React.forwardRef<StandaloneDialogRef, { sourceType: SourceType }>(({ sourceType }, ref) => {
  function closeDialog() {
    (ref as React.MutableRefObject<StandaloneDialogRef>).current?.close();
  }

  return (
    <StandaloneDialog ref={ref}>
      <AuthDialogContent sourceType={sourceType} closeDialog={closeDialog} />
    </StandaloneDialog>
  );
});

// REUSABLE SUMMARIES

/**
 * Implement this method if source's summaries can be reused. It should return a key that uniquely identifies the source configuration.
 */
export const getReusableSummaryConfigKey = multimethod(
  (source: Source) => source.sourceType,
  (_source) => null as string | null, // by default summaries are not reusable
);
